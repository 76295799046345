<template>
  <lab-task>
    <new-lab-calorimetry @allGuidanceComplete="submitResponse" />
  </lab-task>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import NewLabCalorimetry from '@/tasks/components/simulations/NewLabCalorimetry.vue';
import {CompletableSimMixin} from '@/tasks/mixins/completable-sim';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';

export default defineComponent({
  name: 'NewCalorimetryPartOne',
  components: {LabTask, NewLabCalorimetry},
  mixins: [CompletableSimMixin()],
});
</script>
