




import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'NewLabCalorimetry',
  components: {LabSim},
  inheritAttrs: false,
});
